import React from "react";
import { Link } from "react-router-dom";
import "./Cards.css";

const Cards = () => {
  const cardsData = [
    {
      id: 1,
      title: "Past Inventory",
      subtitle: "", // For later text
      link: "/past-inventory", // Subpage URL
      backgroundImage: "/img/cards/Past-Inventory.jpg",
    },
    {
      id: 2,
      title: "Current Inventory",
      subtitle: "", // For later text
      link: "/current-inventory",
      backgroundImage: "/img/cards/Current-Inventory.jpg",
    },
    {
      id: 3,
      title: "Trade Now",
      subtitle: "", // For later text
      link: "https://neoxa.app/",
      backgroundImage: "/img/cards/Trade-Now.jpg",
    },
  ];

  return (
    <div className="cards-container">
      {cardsData.map((card) => {
        if (card.id === 3) {
          return (
            <a
              key={card.id}
              href={card.link}
              className="card"
              style={{ backgroundImage: `url(${card.backgroundImage})` }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="card-content">
                <div className="card-title">{card.title}</div>
                <div className="card-subtitle">{card.subtitle}</div>
              </div>
            </a>
          );
        } else {
          return (
            <Link
              key={card.id}
              to={card.link}
              className="card"
              style={{ backgroundImage: `url(${card.backgroundImage})` }}
            >
              <div className="card-content">
                <div className="card-title">{card.title}</div>
                <div className="card-subtitle">{card.subtitle}</div>
              </div>
            </Link>
          );
        }
      })}
    </div>
  );
};

export default Cards;