import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import About from "./components/About";
import Cards from "./pages/Cards";
import Contact from "./pages/Contact";
import How from "./pages/How";
import CurrentInventory from './pages/CurrentInventory';
// import Login from './components/Login';
import styled from 'styled-components';
import './App.css';

const Container = styled.div`
  background-color: rgb(40, 44, 52);
  height: 100vh;
  width: 100vw;
`;

function App() {
  return (
    <BrowserRouter>
        <Container>
          <Routes>
            <Route path="/" element={
              <div>
              <About />
              <Cards />
              <How />
              <Contact />
              </div>
              } />
            <Route path="/current-inventory" element={<CurrentInventory />} />
          </Routes>
        </Container>
    </BrowserRouter>
  );
}

export default App;