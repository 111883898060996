export const cardsPool = [
  {
    id: 1,
    title: "Black Lotus (Scrye Magazine)",
    image: "/img/all-slabs/Black Lotus Scrye Magazine - Oversize Cards OVER.jpg"
  },
  {
    id: 2,
    title: "Blacker Lotus",
    image: "/img/all-slabs/Blacker_Lotus_ Unglued_UGL.jpg"
  },
  {
    id: 3,
    title: "Blastoise - Base Set (Shadowless)",
    image: "/img/all-slabs/Blastoise_Base_Set_Shadowless_ BSS.jpg"
  },
  {
    id: 4,
    title: "Blue-Eyes White Dragon (Dark Duel Stories)",
    image: "/img/all-slabs/Blue-Eyes White Dragon (Dark Duel Stories) - Yu-Gi-Oh! Video Game Promotional Cards (VDP).jpg"
  },
  {
    id: 5,
    title: "Blue-Eyes White Dragon (Quarter Century Secret Rare)",
    image: "/img/all-slabs/Blue-Eyes White Dragon Quarter Century Secret Rare - 25th Anniversary Ultimate Kaiba Set KC01.jpg"
  },
  {
    id: 6,
    title: "Blue-Eyes White Dragon (Secret Pharaoh's Rare)",
    image: "/img/all-slabs/Blue-Eyes White Dragon Secret Pharaoh's Rare - Magnificent Mavens MAMA.jpg"
  },
  {
    id: 7,
    title: "Dark Magician Girl - The Dark Side of Dimensions",
    image: "/img/all-slabs/Dark Magician Girl - The Dark Side of Dimensions Movie Pack Gold Edition MVP1-ENG.jpg"
  },
  {
    id: 8,
    title: "Dark Magician Girl (2020) - The Lost Art Promotion",
    image: "/img/all-slabs/Dark Magician Girl (2020) - The Lost Art Promotion (LART).jpg"
  },
  {
    id: 9,
    title: "Dark Magician Girl (Ghost Rare)",
    image: "/img/all-slabs/Dark Magician Girl (Ghost Rare) - Ghosts From the Past The 2nd Haunting (GFP2).jpg"
  },
  {
    id: 10,
    title: "Dark Magician Girl the Dragon Knight (Alternate Art)",
    image: "/img/all-slabs/Dark Magician Girl the Dragon Knight (Alternate Art) (Blue) - Dragons of Legend The Complete Series (DLCS).jpg"
  },
  {
    id: 11,
    title: "Dark Magician Girl the Dragon Knight (Quarter Century Secret Rare)",
    image: "/img/all-slabs/Dark Magician Girl the Dragon Knight Quarter Century Secret Rare - Quarter Century Bonanza.jpg"
  },
  {
    id: 12,
    title: "Jeweled Lotus (Extended Art)",
    image: "/img/all-slabs/Jeweled Lotus (Extended Art) - Commander Legends (CMR).jpg"
  },
  {
    id: 13,
    title: "Pikachu ex - Surging Sparks",
    image: "/img/all-slabs/Pikachu ex - 238191 - SV08 Surging Sparks (SV08).jpg"
  },
  {
    id: 14,
    title: "Shining Charizard - Neo Destiny",
    image: "/img/all-slabs/Shining Charizard - Neo Destiny N4.jpg"
  }
];

export const getFeaturedCards = (count) => {
  const shuffled = [...cardsPool].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
};
